export const SERVER = 'http://84.201.176.202';
export const API = SERVER + ':7005/api/v1';

export const  LOG_ROUTE = API + '/converterLog';
export const  CONVERT_ROUTE = API + '/convertFile';

export const TOKEN = 'sdfd-rhhv-dfgj-1347';

export const OPTION_TYPES = {
  SWITCH: 'switch',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
};
