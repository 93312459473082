export const users = [
  {
    "id": 1,
    "login": "user",
    "password": "asd$GE3!"
  },
  {
    "id": 2,
    "login": "user1",
    "password": "bdNf3331M"
  },
  {
    "id": 3,
    "login": "user2",
    "password": "Mnb[k4q"
  }
]